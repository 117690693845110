.Row {
  display: flex;
  flex-direction: row;
}

.package-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-bottom: 2em;
  margin-left: 10px;
  margin-right: 10px;
  height: 100%;
}

.package-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
}

.package-card h5,
.package-card p {
  text-align: center;
}

.package-card ul {
  list-style-position: inside;
}

.package-card button {
  margin: 1em auto;
  display: block;
}

.btn-about {
  background-color: rgb(32, 201, 10);
  font-weight: 600;
}

.btn-about:hover {
  background-color: black;
  color: rgb(32, 201, 10);
  text-decoration: none;
}

.link {
  text-decoration: none;
}

.disclaimer {
  color: red;
  font-weight: bold;
}

.faq {
  margin-top: 2em;
  text-align: center;
}

.faq h3 {
  margin-top: 1em;

}

.faq p {
  margin-left: 1em;
}