.navbar {
    background-color: rgb(1, 192, 250);
}

.brand-and-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-link {
    color: black;
    font-size: 1.25rem;
    font-weight: 500;
}

.nav-link:hover {
    color: rgb(252, 232, 190);
    transform: translateY(-5px);

}

.nav-link.active {
    color: rgba(54, 51, 51, 0.90) !important;
}

.navbar-toggler {
    border-color: gold !important;
}

.logo {
    max-height: 150px;
    margin: 0 auto;
}

.navbar-toggler {
    border-color: brown !important;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='black' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.motto {
    /* Add styles for the motto text */
    color: gold;
    font-size: 1.25rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    background-color: rgb(32, 201, 10);
}

.brand-and-nav {
    display: flex;
    align-items: center;
}

.business-name {
    font-size: 2rem;
    margin-right: auto;
    margin-left: auto;
}

.logo {
    max-height: 100px;
    max-width: 100px;
}

@media (max-width: 768px) {
    .business-name {
        display: none;
    }

    .logo {
        max-height: 200px;
        max-width: 200px;
    }
}