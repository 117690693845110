.sticky-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.btn-home {
  color: black;
  background-color: gold;
  font-weight: 600;
  border: 1px solid black;
}

.btn-home:hover {
  background-color: white;
  color: rgb(32, 201, 10);
}

.modal-header {
  background-color: rgb(32, 201, 10);
}

img {
  width: 100%;
  height: auto;
}