.footer {
  color: white;
  background-color: black;
  width: 100%;
  text-align: center;
}

.gold {
  color:gold;
}

.gold.active {
  color: white !important;
}

.gold:hover {
  color: yellow;
}

.white {
  color: white;
}

.footer-icon {
  font-size: 3rem;
  border-radius: 20%;
}

.footer-icon:hover {
  background-color: yellow;
  color: black;
  font-size: 3.5rem;
  transform: translateY(-5px);

}

.footer .footer-link {
  color: #20c997;
}

.footer .footer-link:hover {
  transform: translateY(-5px);
  color: rgb(0, 165, 63);
}

.footer-h5 {
  padding-bottom: 10%;
}

.credit {
  color: gold;
  text-decoration: none;
}

.credit-text {
  color: gold;
}

.credit:hover {
  color: yellow;
}